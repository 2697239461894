import styled from 'styled-components'
import {
  position,
  space,
  layout,
  color,
  border,
  typography,
  flexbox,
  background,
} from 'styled-system'

export const StyledContainer = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}
  ${background}
`

export const StyledHeading = styled.h1`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}
  ${background}
`

export const StyledText = styled.p`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}

  ${({ textTransform }) => ({ textTransform })}

  a:hover {
    border-bottom: 2px solid;
    padding-bottom: 2px;
  }
`

export const StyledBlockquote = styled.blockquote`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}
  ${background}

  ${({ textTransform }) => ({ textTransform })}

  ${StyledText}:not(:first-child) {
    padding-top: 16px;
  }
  ${StyledText}:not(:last-child) {
    padding-bottom: 16px;
  }
`
