import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { StyledContainer } from './styles'
import Slide from './Slide'
import gsap from 'gsap'

const Carousel = ({ slides }) => {
  const slideContainerRef = useRef()
  const [slideIndex, setSlideIndex] = useState(0)

  const restartFade = useCallback(() => {
    const element = slideContainerRef.current
    const tl = gsap
      .timeline({
        paused: true,
        defaults: { duration: 0.5 },
      })
      .to(element, { opacity: 0 })
      .to(element, { opacity: 1 })

    tl.restart()
  }, [])

  const changeSlide = useCallback(
    indexChange => {
      if (!slides?.length) return
      if (indexChange !== 1 && indexChange !== -1) return

      setTimeout(() => {
        if (indexChange === 1) {
          setSlideIndex(currIndex =>
            currIndex + 1 > slides?.length - 1 ? 0 : currIndex + 1
          )
        }

        if (indexChange === -1) {
          setSlideIndex(currIndex =>
            currIndex - 1 < 0 ? slides?.length - 1 : currIndex - 1
          )
        }
      }, 500)
      restartFade()
    },
    [restartFade, slides.length]
  )

  return (
    <StyledContainer ref={slideContainerRef} className="carousel-container">
      <Slide {...slides?.[slideIndex]} changeSlide={changeSlide} />
    </StyledContainer>
  )
}

Carousel.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.object),
}

export default Carousel
