import React from 'react'
import PropTypes from 'prop-types'
import {StyledPurpleText} from './styles'

const PurpleText = ({ text }) => {
  return <StyledPurpleText color="#38aeeb" fontWeight="100">{text}</StyledPurpleText>
}

PurpleText.propTypes = {}

export default PurpleText
