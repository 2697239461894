import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer } from '@components/Navigation/styles'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Image } from '@components/Image'
import { StyledHeading, StyledText } from './styles'
import Arrow from '@assets/svg/arrow-up-right.svg'
import { A } from '@components/A'
import CarouselArrowLeft from '@assets/svg/carousel-arrow-left.svg'
import CarouselArrowRight from '@assets/svg/carousel-arrow-right.svg'

const Slide = ({
  media,
  heading,
  eyebrow,
  description,
  cta,
  changeSlide = () => {},
}) => {
  return (
    <StyledContainer
      display="flex"
      flexDirection={['column', 'column', 'row']}
      mt={2}
      mb={0}
    >
      <StyledContainer width={['100%', '100%', '50%']}>
        <Image {...media} />
      </StyledContainer>
      <StyledContainer
        width={['100%', '100%', '50%']}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        px={[0, 0, 4]}
        pt={4}
        pb={0}
      >
        <StyledText textTransform="uppercase" fontSize="20px" fontWeight={700}>
          {eyebrow}
        </StyledText>
        <A to={cta?.slug} href={cta?.url}>
          <StyledContainer my={2} display="flex" justifyContent="space-between">
            <StyledHeading
              color="white"
              fontWeight={300}
              fontSize="48px"
              pr={3}
            >
              {heading}
            </StyledHeading>
            <StyledContainer
              borderLeft="1px solid"
              borderColor="lightStroke"
              pl={1}
            >
              <Arrow />
            </StyledContainer>
          </StyledContainer>
        </A>
        <StyledContainer>
          {documentToReactComponents(JSON.parse(description?.raw))}
        </StyledContainer>
        <StyledContainer my={2} display="flex">
          <StyledContainer mr={3}>
            <CarouselArrowLeft
              onClick={() => changeSlide(-1)}
              cursor="pointer"
            />
          </StyledContainer>
          <CarouselArrowRight onClick={() => changeSlide(1)} cursor="pointer" />
        </StyledContainer>
      </StyledContainer>
    </StyledContainer>
  )
}

Slide.propTypes = {
  media: PropTypes.object,
  heading: PropTypes.string,
  eyebrow: PropTypes.string,
  description: PropTypes.object,
  cta: PropTypes.object,
}

export default Slide
