/* eslint-disable react/display-name */
import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const defaultOptions = props => ({
  renderText: text =>
    text
      .split('\n')
      .reduce(
        (children, textSegment, index) => [
          ...children,
          index > 0 && <br key={index} />,
          textSegment,
        ],
        []
      ),
})

export const renderRichText = (richText, customOptions = defaultOptions) => {
  if (!richText) return null
  if (richText.hasOwnProperty('json') && !richText?.json) return null

  // Case for preview (only has `json`)
  // Case for array mapped rich text (each entry has its own rich text container)
  // richtText.raw is pre-parsed and references are forwarded (see Content.js for example)
  if (!richText?.raw) return documentToReactComponents(richText, customOptions)

  // Case where richText is in the shape {raw, references}
  // Gatsby-source-contentful generates `raw` and `references`
  return documentToReactComponents(JSON.parse(richText?.raw), customOptions)
}
