import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledContainer,
  StyledGatsbyImage,
  StyledVanillaImage,
} from './styles'

const Image = ({
  landscape,
  portrait,
  width = '100%',
  height = '100%',
  objectFit = 'cover',
  srcType = 'url',
  flex = "1",
  margin = "0",
  ...rest
}) => {
  // TODO: change to dynamic for mobile
  const responsiveSrc = landscape || portrait
  if (!responsiveSrc) return null

  // TODO: Fix Gatsby Image has flicker and low quality

  const getSrc = () => {
    switch (srcType) {
      case 'fullWidth':
        return responsiveSrc?.fullWidth
      case 'constrained':
        return responsiveSrc?.constrained
      case 'url':
      default:
        return responsiveSrc?.file?.url
    }
  }

  const src = getSrc()
  const alt = src?.description || src?.title

  return (
    <StyledContainer width={width} height={height} flex={flex} margin={margin} >
      {srcType === 'url' ? (
        <StyledVanillaImage
          src={src}
          alt={alt}
          width="100%"
          height="100%"
          objectFit={objectFit}
          {...rest}
        />
      ) : (
        <StyledGatsbyImage
          image={src}
          alt={alt}
          objectFit={objectFit}
          height="100%"
          {...rest}
        />
      )}
    </StyledContainer>
  )
}

Image.propTypes = {
  landscape: PropTypes.object,
  portrait: PropTypes.object,
  alt: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  aspectRatio: PropTypes.number,
}

export default Image
