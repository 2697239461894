/* eslint-disable react/display-name */
import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer } from '@components/A/styles'
import { renderRichText } from './renderRichText'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { StyledBlockquote, StyledText } from './styles'
import { CenterVerticalLine } from '@components/CenterVerticalLine'

const options = () => ({
  renderText: text =>
    text
      .split('\n')
      .reduce(
        (children, textSegment, index) => [
          ...children,
          index > 0 && <br key={index} />,
          textSegment,
        ],
        []
      ),
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <StyledText
        fontSize={['25px', '25px', '32px', '32px']}
        lineHeight={['32px', '32px', '38px', '38px']}
        fontWeight={300}
      >
        {children}
      </StyledText>
    ),
    [BLOCKS.QUOTE]: (node, children) => (
      <StyledBlockquote
        fontSize={['25px', '25px', '32px', '32px']}
        lineHeight={['32px', '32px', '38px', '38px']}
        fontWeight={300}
        borderLeft="1px solid"
        borderColor="lightPurple"
        pl={4}
        my={4}
      >
        {children}
      </StyledBlockquote>
    ),
  },
})

const TextModule = ({ content }) => {
  return (
    <StyledContainer>
      <CenterVerticalLine my={4} />
      {renderRichText(content, options())}
    </StyledContainer>
  )
}

TextModule.propTypes = {}

export default TextModule
