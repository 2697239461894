import React, { useRef } from 'react';
import PropTypes from 'prop-types'
import { StyledContainer } from './styles'
import Statement from './Statement'

const Story = ({ statements = [] }) => {
  const statementRef = useRef()

  return (
    <StyledContainer
      ref={statementRef}
      maxWidth={['100%', '100%', '70%']}
      mt={4}
    >
      {statements?.map((statement, i) => (
        <Statement
          key={`statement-${i}`}
          statement={statement}
        />
      ))}
    </StyledContainer>
  )
}

Story.propTypes = {
  statements: PropTypes.arrayOf(PropTypes.string),
}

export default Story
