import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer } from './styles'

const CenterVerticalLine = props => {
  return (
    <StyledContainer display="flex" height="300px" {...props}>
      <StyledContainer
        width="50%"
        borderRight="1px solid"
        mt={0}
        borderColor="lightPurple"
      ></StyledContainer>
      <StyledContainer mt={0} width="50%"></StyledContainer>
    </StyledContainer>
  )
}

CenterVerticalLine.propTypes = {}

export default CenterVerticalLine
