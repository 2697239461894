import React from 'react'
import PropTypes from 'prop-types'
import { useLocale } from '@hooks/'
import { addLeadingSlash, removeTrailingSlash } from '@utils/'
import { StyledLink } from './styles'
import { isFeatureEnabled, featureFlags } from '../../../featureflags'


const LocalizedLink = ({ to, children, ...rest }) => {
  const { getLocalizedSlug } = useLocale()

  const localizedSlug = getLocalizedSlug(to)
  let sanitizedSlug = addLeadingSlash(localizedSlug)
  sanitizedSlug = sanitizedSlug?.replace(`/home`, '/')
  sanitizedSlug = removeTrailingSlash(sanitizedSlug)

  return (
    <StyledLink to={sanitizedSlug} {...rest}>
      {children}
    </StyledLink>
  )
}

const InternalLink = ({ to, children, ...rest }) => {

  if (isFeatureEnabled(featureFlags.IS_LOCALIZED)) {
    return (
      <LocalizedLink to={to} {...rest}>
        {children}
      </LocalizedLink>
    )
  }
  return (
    <StyledLink to={to} {...rest}>
      {children}
    </StyledLink>
  )
}


InternalLink.propTypes = {
  to: PropTypes.string,
}

export default InternalLink
