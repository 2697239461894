import styled from 'styled-components'
import {
  position,
  space,
  layout,
  color,
  border,
  typography,
  flexbox,
} from 'styled-system'

export const StyledNav = styled.nav`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: transform .5s;
  background: rgba(0, 0, 0, 0);
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}

  ${({ transform, visibility, cursor, transition }) => ({
    transform,
    visibility,
    cursor,
    transition,
  })}
  ${({ hide }) =>
  hide ? `transform: translateY(-250%);` : 'transform: translateY(0%);'}
  max-width: 100vw;
`
export const StyledMenuUl = styled.ul`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}

  ${({ transform }) => ({ transform })}
`
export const StyledFooter = styled.footer`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: 'inherit'
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}

  ${({ transform }) => ({ transform })}
`
export const StyledContainer = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}

  ${({ transform }) => ({ transform })}
`

export const StyledInput = styled.input`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: none;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}

  ${({ transform }) => ({ transform })}
`

export const StyledLabel = styled.label`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: none;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}

  ${({ transform }) => ({ transform })}
  `


export const StyledSkipToContent = styled.div`
box-sizing: border-box;
white-space: nowrap;
outline: none;
border: none;
box-shadow: none;
margin: 0;
top: -100%;
left: 0;
transform: translateY(-100%);

&:focus-within{
  top: 0;
  transform: translateY(0%);
}

a {
  padding: 0;
}

a:focus {
  z-index: 2;
  top: 0;
  transform: translateY(0%);
  background-color: black;
}

${space}
${position}
${color}
${typography}
${border}
${layout}
${flexbox}
`
