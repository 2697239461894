import React from 'react'
import PropTypes from 'prop-types'
import ExternalLink from './ExternalLink'
import InternalLink from './InternalLink'

const A = ({ href, to, children, ...rest }) => {
  if (href)
    return (
      <ExternalLink href={href} {...rest}>
        {children}
      </ExternalLink>
    )

  if (to)
    return (
      <InternalLink to={to} {...rest}>
        {children}
      </InternalLink>
    )

  return children
}

A.propTypes = {
  // url: PropTypes.string,
  // slug: PropTypes.string,
}

export default A
