import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  position,
  space,
  layout,
  color,
  border,
  typography,
  flexbox,
} from 'styled-system'

export const StyledBugPosition = styled.div`
  box-sizing: border-box;
  z-index: 3000;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}
`
export const StyledBugBox = styled.div`
  box-sizing: border-box;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}
`
const StyledSVG = styled.svg`
//   display: block;
//   position: absolute;
//   align-self: center;
//   left: 50%;
//   transform: translateX(-50%);
`

const path = `
<svg xmlns="http://www.w3.org/2000/svg" width="437" height="66">
<g fill="#FFF">
<path d="M52.8 64c-2-2.9-2.2-6.9-2.2-10.4V33.8c0-8.9-.3-18.2-23.2-18.2-11.5 0-24.6 2.2-25.2 15.6h.9l16.5.2c.3-2.1 1.3-4.8 6.9-4.8 3 0 5.9 1.2 5.9 4.2 0 2.9-2.4 3.5-4.9 4C18.1 36.5 0 36 0 51c0 10 7.9 14.4 17.3 14.4 5.9 0 11.7-1.3 15.6-5.6v.3c0 1.2.3 2.9.7 4l19.4.1-.2-.2zM32.5 47.3c-.3 4.8-3.4 7.2-7.6 7.2-3.3 0-5.6-2.2-5.6-4.2 0-3.1 2-4 6.1-4.9 2.4-.5 4.9-1.1 7.1-2.2v4.1zm-30-16.2c0-.4.1-.8.2-1.3-.1.4-.2.8-.2 1.3zm.3-2.4v.1c-.1 0 0-.1 0-.1zM33 59.9s.1-.1 0 0zm51.5-42.3h17v5.9h.2c3.3-4.6 8-7 15.1-7 8.3 0 15.9 5.1 15.9 15.7V64H115V39.7c0-5.4-.6-9.1-5.8-9.1-3 0-7.1 1.5-7.1 8.9V64H84.4V17.6h.1zm68.3 27.1c.4 5.3 3.5 9 9 9 2.9 0 6-1.1 7.4-3.3h16.5c-3.6 10-13.3 14.6-23.8 14.6-14.8 0-26-8.7-26-24.2 0-13.5 9.8-24.5 24.5-24.5 18.4 0 26.3 10.3 26.3 28.3h-33.9v.1zm16.9-9.2c0-4.3-3.2-7.8-7.8-7.8-5.3 0-8.3 2.8-9.1 7.8h16.9zm41.6-17.9h9.5v10.9h-9.5v16.2c0 4.3 1 6.2 5.4 6.2 1.3 0 2.7-.1 4-.3V64c-3.6 0-7.6.5-11.3.5-7.5 0-15.8-1.2-15.8-13.8V28.6h-7.9V17.7h7.9V3.6h17.7v14zm69.9 46.3h-18.1l-6.7-28.5h-.2l-6.9 28.5h-18.1l-14.5-46.3h18.4l6.9 28.6h.2l5.9-28.6h16.5l6.2 28.6h.4l6.4-28.6H296l-14.8 46.3zm37.3 1.1c-17.4 0-25.3-11.5-25.3-24.4 0-12.9 7.9-24.3 25.3-24.3s25.3 11.4 25.3 24.3c-.1 12.9-7.9 24.4-25.3 24.4zm0-35.7c-6.7 0-7.6 6.5-7.6 11.4s.9 11.5 7.6 11.5c6.7 0 7.6-6.6 7.6-11.5-.1-4.9-.9-11.4-7.6-11.4zm27.8-11.7h17V25h.2c2.9-5.5 6.6-8.6 13-8.6 1.8 0 3.5.3 5.2.7v15.6c-1.8-.6-3.4-1.2-7-1.2-7 0-10.7 4.1-10.7 14v18.3h-17.7V17.6zM384.7.2h17.7V31l11.2-13.4h20l-16.5 17.5 19.1 28.8H415l-9.5-16.8-3.1 3.4v13.4h-17.7V.2z"/><circle cx="67.7" cy="52.8" r="12.1"/>
</g>
</svg>
`


const ADotBug = props => {
    let width = 110
    let height = 17
  return (
      <a href="https://a.network" target="_blank" rel="noopener noreferrer">
    <StyledBugPosition
        className="adotbug"
        color="white"
        fontSize={["18px", null, null, "20px"]}
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginRight={["30px", null, null, null, '2.5vw']}
    >
        <StyledBugBox
            paddingRight="6px"
            paddingTop="2px"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            part of the
            </StyledBugBox>
        <StyledSVG
          width={width}
          height={height}
          viewBox="0 0 437 66"
          fill="white"
          xmlns="http://www.w3.org/2000/svg"
          >
<path d="M52.8 64c-2-2.9-2.2-6.9-2.2-10.4V33.8c0-8.9-.3-18.2-23.2-18.2-11.5 0-24.6 2.2-25.2 15.6h.9l16.5.2c.3-2.1 1.3-4.8 6.9-4.8 3 0 5.9 1.2 5.9 4.2 0 2.9-2.4 3.5-4.9 4C18.1 36.5 0 36 0 51c0 10 7.9 14.4 17.3 14.4 5.9 0 11.7-1.3 15.6-5.6v.3c0 1.2.3 2.9.7 4l19.4.1-.2-.2zM32.5 47.3c-.3 4.8-3.4 7.2-7.6 7.2-3.3 0-5.6-2.2-5.6-4.2 0-3.1 2-4 6.1-4.9 2.4-.5 4.9-1.1 7.1-2.2v4.1zm-30-16.2c0-.4.1-.8.2-1.3-.1.4-.2.8-.2 1.3zm.3-2.4v.1c-.1 0 0-.1 0-.1zM33 59.9s.1-.1 0 0zm51.5-42.3h17v5.9h.2c3.3-4.6 8-7 15.1-7 8.3 0 15.9 5.1 15.9 15.7V64H115V39.7c0-5.4-.6-9.1-5.8-9.1-3 0-7.1 1.5-7.1 8.9V64H84.4V17.6h.1zm68.3 27.1c.4 5.3 3.5 9 9 9 2.9 0 6-1.1 7.4-3.3h16.5c-3.6 10-13.3 14.6-23.8 14.6-14.8 0-26-8.7-26-24.2 0-13.5 9.8-24.5 24.5-24.5 18.4 0 26.3 10.3 26.3 28.3h-33.9v.1zm16.9-9.2c0-4.3-3.2-7.8-7.8-7.8-5.3 0-8.3 2.8-9.1 7.8h16.9zm41.6-17.9h9.5v10.9h-9.5v16.2c0 4.3 1 6.2 5.4 6.2 1.3 0 2.7-.1 4-.3V64c-3.6 0-7.6.5-11.3.5-7.5 0-15.8-1.2-15.8-13.8V28.6h-7.9V17.7h7.9V3.6h17.7v14zm69.9 46.3h-18.1l-6.7-28.5h-.2l-6.9 28.5h-18.1l-14.5-46.3h18.4l6.9 28.6h.2l5.9-28.6h16.5l6.2 28.6h.4l6.4-28.6H296l-14.8 46.3zm37.3 1.1c-17.4 0-25.3-11.5-25.3-24.4 0-12.9 7.9-24.3 25.3-24.3s25.3 11.4 25.3 24.3c-.1 12.9-7.9 24.4-25.3 24.4zm0-35.7c-6.7 0-7.6 6.5-7.6 11.4s.9 11.5 7.6 11.5c6.7 0 7.6-6.6 7.6-11.5-.1-4.9-.9-11.4-7.6-11.4zm27.8-11.7h17V25h.2c2.9-5.5 6.6-8.6 13-8.6 1.8 0 3.5.3 5.2.7v15.6c-1.8-.6-3.4-1.2-7-1.2-7 0-10.7 4.1-10.7 14v18.3h-17.7V17.6zM384.7.2h17.7V31l11.2-13.4h20l-16.5 17.5 19.1 28.8H415l-9.5-16.8-3.1 3.4v13.4h-17.7V.2z"/><circle cx="67.7" cy="52.8" r="12.1"/>

      </StyledSVG>
    </StyledBugPosition>
      </a>
  )
}

ADotBug.propTypes = {}

export default ADotBug