import { camelize } from '.'

export const stripEscapeChars = str => str.replace(/\\/g, '')

export const parseQueryString = queryVariable => {
  const query = window.location.search.substring(1)

  const variables = query.split('&')
  for (let i = 0; i < variables.length; i++) {
    let pair = variables[i].split('=')
    if (pair[0] === queryVariable) {
      return pair[1]
    }
  }
}

export const getArryOfIds = arr => arr.map(({ sys: { id } }) => id)

export const getLinkedEntry = (arr, targetId) => {
  return arr.find(({ sys: { id } }) => id === targetId)
}

export const getLinkedEntryAndSpreadFields = (arr, targetId) => {
  const linkedEntry = getLinkedEntry(arr, targetId)
  if (!linkedEntry) return null
  const { sys, fields } = linkedEntry
  return {
    id: sys.id,
    type: sys?.contentType?.sys?.id || 'asset',
    ...fields,
  }
}

export const handleRemoveContentfulFromContentTypeString = str => {
  let newString = str

  if (newString?.includes('Contentful')) {
    newString = camelize(newString.slice(10))
  }

  if (newString?.includes('contentBlock')) {
    newString = camelize(newString.slice(12))
  }

  if (newString?.includes('external')) {
    newString = camelize(newString.slice(8))
  }

  if (newString?.includes('component')) {
    newString = camelize(newString.slice(9))
  }
  // specific preview api vs contentful name discrepencies
  switch (newString) {
    case 'personOrTeamMember':
      return 'person'
    default:
      return newString
  }
}

export const resolveLocaleNesting = (node, locale, defaultLocale) => {
  if (typeof node === 'object') {
    return node[locale] ?? node[defaultLocale] ?? node
  }
  return node
}

export const recursivelyDestructureFields = (node, locale, defaultLocale) => {
  node = resolveLocaleNesting(node, locale, defaultLocale)
  if (node?.fields) {
    let newFields = {
      id: node.sys.id,
      contentType: node.sys?.contentType?.sys?.id || node.sys?.type,
      ...node.fields,
    }

    const entries = Object.entries(newFields)
    entries.forEach(([key, val]) => {
      const localizedVal = resolveLocaleNesting(val, locale, defaultLocale)
      if (Array.isArray(localizedVal)) {
        newFields[key] = localizedVal.map(innerVal =>
          recursivelyDestructureFields(innerVal, locale, defaultLocale)
        )
      } else {
        newFields[key] = recursivelyDestructureFields(
          localizedVal,
          locale,
          defaultLocale
        )
      }
    })

    return newFields
  }
  return node
}

export const recursivelyGetLinkedEntriesAndSpreadFields = (
  item,
  Entry,
  Asset,
  maxCallStack = 4
) => {
  let newFields = item
  if (!newFields) return undefined
  if (newFields?.slug)
    newFields.slug = formatPreviewSlug(newFields?.id, newFields?.type)
  if (maxCallStack === 0) return newFields

  const getNewVal = val => {
    const arr = val?.sys?.linkType === 'Entry' ? Entry : Asset
    const targetId = val?.sys?.id
    if (targetId) {
      const linkedEntry = getLinkedEntryAndSpreadFields(arr, targetId)
      return recursivelyGetLinkedEntriesAndSpreadFields(
        linkedEntry,
        Entry,
        Asset,
        maxCallStack - 1
      )
    }
    return val
  }

  const fields = Object.entries(newFields)

  fields.forEach(([key, val]) => {
    if (typeof val === 'object') {
      if (Array.isArray(val)) {
        newFields[key] = val.map(innerVal => getNewVal(innerVal))
      } else {
        newFields[key] = getNewVal(val)
      }
    }
  })

  return newFields
}

export const formatPreviewSlug = (id, type = 'page', pageNumber = '') =>
  `preview/?t=${parseQueryString('t')}&id=${id}&contentType=${type}${
    pageNumber ? `&pageNumber=${pageNumber}` : ''
  }`

export const recursivelyRemoveContentfulPrefix = ({
  contentType,
  ...rest
} = {}) => {
  const newEntry = {
    contentType:
      handleRemoveContentfulFromContentTypeString(contentType) || contentType,
    ...rest,
  }

  const entries = Object.entries(newEntry)
  entries.forEach(([key, val]) => {
    if (Array.isArray(val)) {
      newEntry[key] = val?.map(innerVal =>
        typeof innerVal === 'object'
          ? recursivelyRemoveContentfulPrefix(innerVal)
          : innerVal
      )
    } else if (val?.contentType) {
      newEntry[key] = recursivelyRemoveContentfulPrefix(val)
    }
  })
  return newEntry
}

export const getParentPathSlug = ({ slug, type, localeSlug, category }) => {
  switch (type) {
    case 'person':
    case 'ContentfulPerson':
      return `${localeSlug}/people/${slug}`
    case 'page':
    case 'ContentfulPage':
      return `${localeSlug}/${slug}`
    case 'article':
    case 'ContentfulArticle':
      return `${localeSlug}/${category?.slug}/${slug}`
    default:
      if (slug === 'home' || slug === '/') return `${localeSlug}/`
  }
}

export const formatContentGroupUrls = (items, preview = false, localeSlug) => {
  return items?.map(
    ({ url, slug, type, contentType, author, id, category, ...rest }) => ({
      ...rest,
      category: category,
      id: id,
      author: author && {
        ...author,
        slug: !preview // need to check for embedded
          ? `${localeSlug}/people/${author?.slug}`
          : `${localeSlug}/${author?.slug}`,
      },
      link:
        type === 'link' ||
        type === 'ContentfulComponentExternalLink' ||
        contentType === 'link' ||
        contentType === 'ContentfulComponentExternalLink'
          ? url
          : preview
          ? `${localeSlug}/${slug}`
          : getParentPathSlug({
              slug,
              type: type || contentType,
              localeSlug,
              category,
            }),
      type: type || contentType,
    })
  )
}
