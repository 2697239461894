import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer } from './styles'
import { Story } from '@components/Story'
import { Carousel } from '@components/Carousel'
import { TextModule } from '@components/TextModule'
import { PlaceholderModule } from '@components/Placeholder'

const PageModules = ({ modules = [] }) => {
  const renderModule = (typename, rest) => {
    switch (typename) {
      case 'ContentfulModuleStory':
        return <Story {...rest} />
      case 'ContentfulModuleCarousel':
        return <Carousel {...rest} />
      case 'ContentfulModuleText':
        return <TextModule {...rest} />
      case 'ContentfulModulePlaceholder':
        return <PlaceholderModule {...rest} />
      default:
        return null
    }
  }

  return (
    <StyledContainer
      display="flex"
      flexDirection="column"
      maxWidth={null}
      mx={'auto'}
      my={null}
    >
      {modules?.map(({ id, __typename, ...rest }) => (
        <StyledContainer key={id} my={0} >
          {renderModule(__typename, rest)}
        </StyledContainer>
      ))}
    </StyledContainer>
  )
}

PageModules.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.object),
}

export default PageModules