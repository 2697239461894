import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import {
  position,
  space,
  layout,
  color,
  border,
  typography,
  flexbox,
} from 'styled-system'

export const StyledContainer = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}
`

export const StyledVanillaImage = styled.img`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}

  ${({ objectFit }) => ({ objectFit })}
`

export const StyledGatsbyImage = styled(GatsbyImage)`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}

  ${({ aspectRatio }) => aspectRatio && `aspect-ratio: ${aspectRatio};`}
`
