import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { StyledText } from './styles'
import { gsap } from 'gsap'

const Statement = ({ statement, gradient }) => {
  const ref = useRef()

  useEffect(() => {
    const element = ref.current
    gsap.to(element, {
      scrollTrigger: {
        trigger: ref?.current,
        start: 'top center',
        end: 'bottom center',
        toggleActions: 'play none none none',
      },
      opacity: 1,
      y: 0,
      duration: 1.5,
    })
  }, [])

  return (
    <StyledText
      ref={ref}
      py={4}
      background={gradient}
      fontSize={['51px', '51px', '76px', '76px']}
      lineHeight={['71px', '71px', '96px', '96px']}
      fontWeight={300}
      opacity={0}
      transform="translateY(100px)"
      my={4}
    >
      {statement}
    </StyledText>
  )
}

Statement.propTypes = {}

export default Statement
