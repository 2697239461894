import styled from 'styled-components'
import {
  position,
  space,
  layout,
  color,
  border,
  typography,
  flexbox,
  background,
} from 'styled-system'

export const StyledContainer = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}

  h1:first-child {
    margin-top: 0;
  }
`

export const StyledText = styled.h1`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}
  background: linear-gradient(#F4476C, #38AEEB);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;

  ${({ transform }) => ({ transform })}

  // Needs to be placed styled-system background var
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
