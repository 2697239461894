import React from 'react'
import PropTypes from 'prop-types'
import { StyledBlueText } from './styles'
// reference PNG logo here
// using actual museo sans display to speed up build
// import png from '@assets/WorkThatThinks.png'

const BlueText = ({ text }) => {
  return (<>
  <StyledBlueText
    color="#38aeeb"
    fontWeight="100"
    letterSpacing="1.4px"
    >
      {text}
    </StyledBlueText>
    </>
  )
}

BlueText.propTypes = {}

export default BlueText
