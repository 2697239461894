/* eslint-disable react/display-name */
import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer } from '@components/A/styles'
import { renderRichText } from './renderRichText'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import {
  StyledBlockquote,
  StyledText,
  StyledBorder,
  StyledSubtitle,
  StyledFooterColumn,
  StyledFooter,
} from './styles'
import { Image } from '@components/Image'
import { PurpleText, BlueText } from '@components/InlineText'
import desktopBackground from '/src/assets/stroke-desktop.svg'
import tabletBackground from '/src/assets/stroke-tablet.svg'
import mobileBackground from '/src/assets/stroke-mobile.svg'
import ADotBug from '@components/ADotBug'

const options = ({ references } = {}) => ({
  renderText: text =>
    text
      .split('\n')
      .reduce(
        (children, textSegment, index) => [
          ...children,
          index > 0 && <br key={index} />,
          textSegment,
        ],
        []
      ),
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <StyledText>
        {children}
      </StyledText>
    ),
    [BLOCKS.QUOTE]: (node, children) => (
      <StyledBlockquote
        fontSize={['25px', '25px', '32px', '32px']}
        lineHeight={['32px', '32px', '38px', '38px']}
        fontWeight={300}
        borderLeft="1px solid"
        borderColor="lightPurple"
        pl={4}
        my={4}
      >
        {children}
      </StyledBlockquote>
    ),
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      const entryId = node?.data?.target?.sys?.id
      const entry = references?.find(({ id }) => id === entryId)

      switch (entry?.__typename) {
        case 'ContentfulPurpleText':
          return <PurpleText {...entry} />
          case 'ContentfulBlueText':
            return <BlueText {...entry} />
        default:
          return null
      }
    },
  },
})

const Border = ({ children, backgroundImage }) => {
  return <StyledBorder backgroundImage={[`url(${mobileBackground})`, `url(${tabletBackground})`, `url(${desktopBackground})`]}>{children}</StyledBorder>
}

const Subtitle = ({fontSize, fontWeight, lineHeight, color, children}) => {
  return <StyledSubtitle 
      fontSize={fontSize} 
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      color={color}
    >
      {children}
  </StyledSubtitle>
}

const PlaceholderFooter = ({fontSize, fontWeight, lineHeight, color, flex, display, flexDirection, justifyContent, alignItems, children}) => {
  return <StyledFooter 
      fontSize={fontSize} 
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      color={color}
      flex={flex}
      display={display}
      flexDirection={flexDirection}
      justifyContent={justifyContent}
      alignItems={alignItems}
    >
      {children}
  </StyledFooter>
}

const PlaceholderModule = ({ subtitle, footer }) => {
  return (
    <StyledContainer width="100vw" height="100vh" margin="0">
      <Border>
        <StyledContainer
          flex="8"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          paddingTop={[0, 0, '2rem', '2rem']}
        >
          <Image
            objectFit="contain"
            portrait
            srcType="url"
            src="images/nc-logo-neon-1.webp"
            width="100%"
            margin="0 auto"
            alt="nightcap"
            height="auto"
            flex="none"
          /><br/>
          <Subtitle
            fontSize={['6.5vw', null, '5vw']}
          >
            {renderRichText(
              subtitle,
              options({ references: subtitle?.references })
            )}
          </Subtitle>
        </StyledContainer>
        <PlaceholderFooter
          flex="1"
          fontSize={['1rem', '1rem', '2.4vw', '2.4vw']}
          fontWeight="300"
          color="#fff"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
            {renderRichText(footer, options())}
        </PlaceholderFooter>
        <PlaceholderFooter
          flex="1"
          fontSize={['1rem', '1rem', '2.4vw', '2.4vw']}
          fontWeight="300"
          color="#fff"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-end"
        >
        <ADotBug />
        </PlaceholderFooter>
      </Border>
    </StyledContainer>
  )
}

PlaceholderModule.propTypes = {}

export default PlaceholderModule
